
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class TableRow extends Vue {
  @Prop({ default: false })
  private readonly clickable!: boolean;
}

