
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Paging extends Vue {
  @Prop({ required: true })
  private readonly page!: number;

  @Prop({ required: true })
  private readonly pages!: number;

  get pageRange (): number[] {
    const pages: number[] = []
    // let startPage: number
    // if (this.page < 5) {
    //   startPage = 2
    // } else if (this.page > this.pages - 4) {
    //   startPage = this.pages - 4
    // } else { startPage = this.page - 1 }
    // let endPage: number
    // if (this.page > this.pages - 4) {
    //   endPage = 9
    // } else if (this.page < 5) {
    //   endPage = 5
    // } else {
    //   endPage = this.page + 1
    // }
    const startPage = Math.max(2, this.page - 1)
    const endPage = Math.min(this.pages - 1, this.page + 1)
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }
    return pages
  }

  getScheme (index: number): string {
    return index === this.page ? 'primary' : 'default'
  }

  onPageNav (page: number): void {
    this.$emit('paged', page)
  }
}
