import PagedFilterBase from './PagedFilterBase'

class EmailNewsletterSubscriptionFilter extends PagedFilterBase {
    EmailAddress?: string;

    constructor(init?: Partial<EmailNewsletterSubscriptionFilter>) {
        super()

        if (init) Object.assign(this, init);
    }
}

export default EmailNewsletterSubscriptionFilter
