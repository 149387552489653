
import { Vue, Component, Prop } from 'vue-property-decorator'
import TableRow from './TableRow.vue'

@Component({
  components: {
    TableRow
  }
})

export default class Table extends Vue {
  @Prop({ default: () => [] })
  private readonly headings!: string[];
}

