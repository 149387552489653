class FilterBase {
    ToQueryParams(): Record<string, string> {
        const items: Record<string, string> = {}

        Object.entries(this).forEach(([key, value]) => {
            // const item = Object.getOwnPropertyDescriptor(this, k);

            //   if (Array.isArray(item?.value)) {
            //     console.log("item is array")
            //     // console.log(item.value)
            //     // items[encodeURIComponent(k)] = encodeURIComponent(item.value.join(','))
            //   } else {
            // if (item?.value !== undefined && item?.value !== null) items[encodeURIComponent(k)] = encodeURIComponent(item.value.toString())
            //   }
            // });
            if (value != null && value != undefined) items[encodeURIComponent(key)] = encodeURIComponent(value.toString())
        });
        return items
    }
}

export default FilterBase
