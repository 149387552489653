import FilterBase from "./FilterBase";

class PagedFilterBase extends FilterBase {
    public Page = 1;
    public PageSize = 30;

    constructor() {
        super()
    }
}

export default PagedFilterBase
