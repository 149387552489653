
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import Badge from '@/components/status/Badge.vue'
import EmailNewsletterSubscriptionReadDto
  from 'tradingmate-modules/src/models/api/email-newsletter-subscriptions/EmailNewsletterSubscriptionReadDto'
import { FormPage } from '@/mixins'
import EmailNewsletterSubscriptionFilter
  from '../../../../../../tradingmate-modules/src/filters/EmailNewsletterSubscription'
import { CategorySelector, InputSelect, InputText, ZoneSelector } from '@/components/inputs'
import { SearchBar, SearchBarItem } from '@/components/search'
import Paging from '@/components/actions/Paging.vue'
import { PagedResultSet } from '../../../../../../tradingmate-modules/src/models/api/common'

@Component({
  components: {
    Paging,
    InputSelect,
    SearchBar,
    CategorySelector,
    SearchBarItem,
    ZoneSelector,
    InputText,
    Badge,
    Table,
    TableHeader,
    TableRow,
    TableData
  }
})

export default class NewsletterSubscriberDatabase extends Mixins(FormPage) {
  private dtos: PagedResultSet<EmailNewsletterSubscriptionReadDto> | null = null

  private filter: EmailNewsletterSubscriptionFilter = new EmailNewsletterSubscriptionFilter({
    EmailAddress: ''
  })

  private unsubbingEmail = ''
  private isExporting = false

  mounted (): void {
    this.getNewsletterSubscriptions()
  }

  async getNewsletterSubscriptions (): Promise<void> {
    this.loading = true
    try {
      this.dtos = await Services.API.EmailNewsletterSubscriptions.Filter(this.filter)
    } catch (errors)
    {
      console.log(errors)
    } finally {
      this.loading = false
    }
  }

  async onUnsubscribe (email: string): Promise<void> {
    this.unsubbingEmail = email
    try {
      await Services.API.EmailNewsletterSubscriptions.Delete(email)
    } catch (errors) {
      console.log(errors)
    } finally {
      this.loading = false
    }

    // Delete from list
    if (!this.dtos) return
    const deletedIndex = this.dtos.Items.findIndex(x => x.EmailAddress === email)
    if (deletedIndex === -1) return
    this.dtos.Items.splice(deletedIndex, 1)
  }

  async onExport (): Promise<void> {
    this.isExporting = true
    let response
    try {
      response = await Services.API.EmailNewsletterSubscriptions.Export(this.filter)
    } catch (errors) {
      console.error(errors)
    }

    const link = document.createElement('a')
    link.href = URL.createObjectURL(response)
    link.download = 'newsletter-subscribers.csv'
    link.click()
    URL.revokeObjectURL(link.href)

    this.isExporting = false
  }

  public onPaged (page: number): void {
    this.filter.Page = page
    this.getNewsletterSubscriptions()
  }
}

