
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class TableData extends Vue {
  @Prop({ default: false })
  private readonly noWrap!: boolean;

  @Prop({ default: false })
  private readonly breakWord!: boolean;

  @Prop({ default: false })
  private readonly autogrow!: boolean;

  @Prop({ default: 1 })
  private readonly colspan!: number;
}

