
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class SearchBarItem extends Vue {
  @Prop({ default: null })
  private readonly label!: string | null;

  @Prop({ default: 6 })
  private readonly width!: number;
}

